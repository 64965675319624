$navbar-height-desktop: 58px;
$navbar-height-mobile: 55px;
$navbar-height-mobile-logged-in: 21px;

/******************/
/* Preloading */
.games-categories-loading {
    display: flex;
    height: 300px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

/******************/
/* Search Field */
.games-search-field-wrapper {
    padding: 25px 10px 15px;

    @media (max-width: 575px) {
        padding: 15px 10px 10px;
    }
}

.games-search-field {
    position: relative;
    max-width: 480px;
    margin: auto;
}

.games-search-field-icon {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 22px;
    color: $font-low;
    z-index: 1002;
}

input[type="search"]::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;

    /* Now your own custom styles */
    height: 16px;
    width: 16px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);

    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;

    /* icon size */
    background-size: 16px;
    color: $font-full;
}

/******************/
/* Game Filter */
.games-filter-container {
    position: sticky;
    position: -webkit-sticky;
    top: $navbar-height-desktop;
    left: 0;
    width: 100%;
    z-index: 1003;
    background-color: $background;
    padding: 10px 0;

    @media (max-width: 575px) {
        padding: 10px 7px;
        top: $navbar-height-mobile;
    }

    &-logged-in {
        @media (max-width: 767px) {
            top: $navbar-height-mobile-logged-in;
        }
    }
}

.games-filter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    @media (max-width: 991px) {
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

/*****************************/
/* Filter buttons */
.games-category {
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    text-align: center;
    

    text-decoration: none;
    border: 2px solid transparent;
    white-space: nowrap;

    color: $font-mid;
    font-weight: 500;

    padding: 7px 20px;
    border-radius: $border-radius;
    margin: 0 0.275rem;

    transition: all 0.3s ease-out;


    &:hover,
    &:active {
        background-color: $background-dark;
        color: $font-mid;
    }

    @media (max-width: 576px) {
        font-size: 14px;
        padding: 0.5rem 0.75rem;
    }

    &-active,
    &-active:hover {
        //background-color: $primary;
        // border: 2px solid $primary;
        font-weight: 600;
        background-color: $background-dark;
        color: $font-full;
    }

    &-placeholder {
        font-size: 0.875rem;
        border-radius: $border-radius;
        margin: 0 0.375rem;
        border: none;
        width: 100px;
        height: 37px;

        @media (max-width: 576px) {
            height: 34px;
        }
    }
}