.cfb-container {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    background-color: $foreground;
    border-radius: $border-radius;
    box-shadow: 0px 1px 3px 1px $background-dark;
    z-index: 1001;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.cfb-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px 20px;
    width: 100%;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 5px 10px;
        align-self: baseline;
        align-items: unset;
    }
}

.cfb-content-text {
    margin-right: 10px;
    
    @media (max-width: 767px) {
        margin-right: 0;
    }
}

.logged-out .cfb-content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 25px 20px;
    width: 100%;

    @media (max-width: 767px) {
        padding: 30px 20px;
    }
}

.logged-out .cfb-badge {
    color: $font-full;
    font-size: 12px;
    font-weight: 600;
}

.cfb-title {
    font-weight: 500;
    margin-bottom: 10px;

    // @media (max-width: 767px) {
    //     text-align: center;
    // }
}

.logged-out .cfb-cta {
    color: $font-full;
    font-weight: 600;
}

.cfb-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    margin-left: 15px;
}

@media (max-width: 767px) {
    .cfb-buttons {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 0px;
        margin-top: 15px;

        & > * {
            width: 100%;
        }
    }

    .cfb-buttons.logged-out {
        margin-top: 15px;
        justify-content: center;
    }
}

.cfb-button-confirm {
    margin-right: 10px;

    @media (max-width: 767px) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

.cfb-image {
    height: 140px;
    border-radius: calc($border-radius - 5px);

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
    }
}

.cfb-modal-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}