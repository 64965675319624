.background-container-home {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 400px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
    background: -moz-linear-gradient(0deg, $background 0%, $foreground 80%);
    background: -webkit-linear-gradient(0deg, $background 0%, $foreground 80%);
    background: linear-gradient(0deg, $background 0%, $foreground 80%);
}

.background-container-games {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 110px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 1;
    background: -moz-linear-gradient(0deg, $background 0%, $foreground 40%);
    background: -webkit-linear-gradient(0deg, $background 0%, $foreground 40%);
    background: linear-gradient(0deg, $background 0%, $foreground 40%);
}