.deposit-bonus-section {
    margin-bottom: 5px;
    // background: $foreground;
    padding: 15px 0 10px;

    @media (max-width: 991px) {
        padding: 10px 0;
        //margin-bottom: 5px;
    }
}

.deposit-bonus-section-title {
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: $font-mid;

    max-width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 991px) {
        max-width: 300px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.bonus-card-row-container {
    position: relative;
}

.bonus-card-row-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    
    grid-auto-columns: 240px;
}

.bonus-card-row-wrapper > button {
    min-height: 174px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.bonus-card-row-wrapper > button:first-child {
    margin-left: 15px;

    @media (max-width: 991px) {
        margin-left: 10px;
    }
}

.bonus-card-row-wrapper > button:last-child {
    margin-right: 15px;

    @media (max-width: 991px) {
        margin-right: 10px;
    }
}

.deposit-bonus-section .scroll_buttons_container .arrow-right,
.deposit-bonus-section .scroll_buttons_container .arrow-left {
    top: -43px;
}

.deposit-bonus-section .scroll_buttons_container .arrow-right {
    right: 15px;
}

.deposit-bonus-section .scroll_buttons_container .arrow-left {
    right: 65px;
}

.bonus-promocode-field-accordion-item {
    background: transparent;
    border: 0px solid transparent;

    .accordion-body {
        padding: 10px 15px;
    }
}

.bonus-promocode-field-link {
    display: inline-block;
}

.bonus-promocode-field-link button {
    padding: 5px 15px;
    background: transparent !important;
    color: $font-mid !important;
    font-size: 14px;
    box-shadow: none !important;
    outline: none !important;
}

.bonus-promocode-field-link button::after {
    content: none;
}