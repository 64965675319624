.question-mark-icon {
    font-size: 14px;
    color: $font-low;
    line-height: 14px;
}

.tooltip.show {
    opacity: 1;
}

.question-mark-tooltip .tooltip-inner {
    padding: 10px;
    text-align: left !important;
    background: #2f2f2f;
    // color: $font-mid;
    border-radius: $border-radius;
    max-width: 230px;

    ul {
        padding-left: 1.25rem;
        margin-bottom: 10px;
    }
}