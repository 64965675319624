.navbar-game {
    height: 50px;
    position: absolute;
    width: 100%;
    z-index: 1005;
    padding: 0;

    @media (max-width: 1199px) {
        background: #000;
        position: fixed;
        top: 0;
        left: 0;

        &-portrait {
            height: 35px;
            width: 100%;

            .navbar-game-wrapper {
                flex-direction: row;
            }

            .navbar-back-links .navbar-game-link:first-child {
                margin-left: 15px;
            }

            .btn-add-funds {
                margin-right: 15px;
            }

            .navbar-game-link {
                padding: 0rem 1.5rem;
            }
        }

        &-landscape {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 35px;

            .navbar-game-wrapper {
                flex-direction: column;
            }

            .navbar-nav {
                flex-direction: column;
            }

            .navbar-back-links .navbar-game-link:first-child {
                margin-top: 15px;
                margin-bottom: 15px;
                margin-right: 0;
            }

            .btn-add-funds {
                margin-bottom: 15px;
            }

            .navbar-game-link {
                padding: 1rem 0.25rem;
            }
        }

    }
}

.navbar-game-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    @media (min-width: 1200px) {
        max-width: calc((100vh - 200px) * 1.777);
    }
}

.navbar-game-link {
    background-color: rgb(0 0 0 / 0.3);
    color: #FFF !important;
    border: none;
    text-decoration: none;

    border-radius: $border-radius;
    text-align: center;
    font-size: 1rem;
    line-height: normal;

    padding: 0.25rem 1.5rem;
}

.navbar-game-link:active,
.navbar-game-link:hover {
    background-color: rgb(0 0 0 / 0.5);
}

.navbar-game-link:active {
    transform: scale(0.95);
}

@media (max-width: 1199px) {
    .navbar-game-link:active,
    .navbar-game-link:hover,
    .navbar-game-link {
        color: #FFF !important;
        border: 1px solid #fff;
        line-height: 1.375rem;
    }

    .navbar-game-link:active {
        transform: scale(0.95);
    }
}

.btn-add-funds {
    font-size: 14px;
    position: relative;
}

.btn-add-funds-red {
    background-color: $error-full;
    border-color: $error-full;
    color: #FFF;
}

.btn-add-funds-alert {
    position: absolute;
    left: 0px;
    top: -3px;
    width: 15px;
    height: 15px;
    border-radius: $border-radius;
    background-color: $error-full;
}

@media (max-width: 1199px) {
    .btn-add-funds-alert {
        width: 13px;
        height: 13px;
    }
}

.navbar-back-links .navbar-game-link:first-child {
    margin-right: 15px;
}