.home_banner-container {
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    border-radius: $border-radius;
    padding: 0;

    background: url(https://cherryspins.b-cdn.net/orozino-test/banner_background_blured-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 60%;

    @media (max-width: 768px) {
        background: url(https://cherryspins.b-cdn.net/orozino-test/banner_background_blured_mobile-1.png);
        background-repeat: no-repeat;
        background-position: 50% 9%;
        background-size: 150% 150%;
    }
}

.home_banner-background {
    position: absolute;
    top: -5%;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;

    @media (max-width: 1399px) {
        top: 0;
    }

    @media (max-width: 1199px) {
        transform: scale(1.2);
        transform-origin: 50% 0%;
    }

    @media (max-width: 991px) {
        transform: scale(1.5);
        transform-origin: 50% 0%;
    }

    @media (max-width: 768px) {
        transform-origin: 50% 25%;
    }

    @media (max-width: 359px) {
        transform-origin: 50% 0%;
    }
}

.home_banner-content_wrapper {
    position: relative;
    z-index: 10;
    height: 560px;
    display: flex;
    width: 100%;

    @media (max-width: 1399px) {
        height: 515px;
    }

    @media (max-width: 768px) {
        height: auto;
    }

    // @media (max-width: 400px) {
    //     min-height: 495px;
    // }

    // @media (max-width: 375px) {
    //     min-height: 485px;
    // }
}

.home_banner-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
    }
}

.home_banner-content>div {
    margin: 0 10px;

    @media (max-width: 768px) {
        margin: 0;
    }
}

.home_banner-offer-wrapper {
    position: relative;

    @media (max-width: 768px) {
        padding: 60px 0 50px;
        text-align: center;
    }

    @media (max-width: 359px) {
        padding: 30px 0 30px;
    }

    &::before {
        content: "";
        width: 100%;
        height: 180px;
        background: rgb(213 244 255 / 75%);
        position: absolute;
        top: -20px;
        left: 0;
        z-index: -1;
        -webkit-filter: blur(40px);
        filter: blur(40px);

        @media (max-width: 768px) {
            content: "";
            width: 100%;
            height: 100px;
            background: rgba(166, 235, 255, 0.75);
            position: absolute;
            top: 35px;
            left: 0;
            z-index: -1;
            filter: blur(40px);
        }
    }
}

.home_banner-form-container {
    width: 450px;
    height: 400px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        padding: 10px;
        width: 100%;
        height: auto;
    }
}

.home_banner-form-wrapper {
    width: 100%;
    background-color: rgb(255 255 255 / 95%);
    border-radius: calc($border-radius - 5px);
    padding: 40px 30px 20px;

    @media (max-width: 768px) {
        padding: 35px 15px 20px;
        background-color: rgb(255 255 255);
    }
}

.home_banner-welcome-wrapper {
    width: 100%;
    background-color: rgb(255 255 255 / 95%);
    border-radius: $border-radius;
    padding: 30px;

    @media (max-width: 768px) {
        padding: 40px 20px;
        background-color: rgb(255 255 255);
    }
}

.home_banner-form-title {
    margin-bottom: 13px;
    font-weight: 600;
}

.home_baner-promo_font {
    font-weight: 600;
    position: relative;
    white-space: nowrap;

    &:before {
        content: "";
        width: calc(125%);
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: -13%;
        font-style: normal;
        background-color: $primary;
        background-position: 0 43%;
        background-size: cover;
        -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
        mask-image: url(https://i.imgur.com/P1bFwVG.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
    }
}

.home_banner-left_tree {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 500px;
    z-index: 4;

    &_image {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;

        @media (max-width: 1399px) {
            left: -30px;
        }

        @media (max-width: 1199px) {
            left: -70px;
        }

        @media (max-width: 550px) {
            left: -100px;
        }
    }

    -webkit-mask-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.home_banner-right_tree {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    height: 500px;
    z-index: 4;

    &_image {
        width: 100%;
        height: auto;
        position: absolute;

        @media (max-width: 1399px) {
            right: -30px;
        }

        @media (max-width: 1199px) {
            right: -50px;
        }

        @media (max-width: 550px) {
            right: -100px;
        }
    }

    -webkit-mask-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}


/**********************/
/** Logged in **/
.home_banner-logged-in {
    padding: 50px 0px 0px;
    text-align: center;

    @media (max-width: 768px) {
        padding: 35px 0px 0px;
    }
}

.home_banner-greeting {
    display: inline-block;
    position: relative;

    &:before {
        content: "";
        width: calc(125%);
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0px;
        left: -13%;
        font-style: normal;
        background-color: $primary;
        background-position: 0 43%;
        background-size: cover;
        -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
        mask-image: url(https://i.imgur.com/P1bFwVG.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;

        @media (max-width: 768px) {
            top: 0;
            left: -10px;
            width: calc(100% + 20px);
        }
    }
}