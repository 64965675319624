.game-row-last-played-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    &>* {
        margin-right: 10px;
    }

    @media (min-width: 1200px) {
        grid-auto-columns: 210px;
        min-height: 230px;

        &>* {
            margin-right: 1.25rem;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-auto-columns: 195px;
        min-height: 218px;

        &>* {
            margin-right: 1rem;
        }
    }

    @media (max-width: 767px) {
        grid-auto-columns: 175px;
        min-height: 198px;

        &>* {
            margin-right: 0.75rem;
        }
    }
}