.deposit_amount_preset {
    width: 100%;
    margin-bottom: 15px;
    padding: 0px;
    border-radius: $border-radius;
    gap: 10px;
}

.deposit_amount_preset_button {
    outline: none !important;
    border: 0px solid transparent;

    flex-basis: 50% !important;
    border-radius: calc($border-radius / 1.3) !important; // $border-radius !important;
    font-weight: 500 !important;

    color: $font-mid !important;
    background: $foreground; // $background;
    box-shadow: 0px 1px 3px 1px $background-dark;

    font-size: 14px !important;
    padding: 9px;

    z-index: 0;

    &:hover,
    &:active,
    &:focus,
    &.active {
        color: $font-full !important;
        border: 0px solid transparent !important;
        background: $background !important;
        box-shadow: 0px 1px 3px 1px $background-dark !important;
    }
}

.deposit_amount_preset .btn-check:checked+.deposit_amount_preset_button {
    color: $font-full !important;
    background: $primary;
    font-weight: 600 !important;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus,
    &.active {
        color: $font-full !important;
        background: $primary !important;
        font-weight: 600 !important;
        box-shadow: none !important;
    }
}