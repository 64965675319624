/** Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --bs-font-sans-serif: "Poppins" !important; // , system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/** Bootstrap */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

/**********************/
/** Typography */
/** Shared */
@import "./spinsoft-core-client-subtree/scss/typography.scss";
/** Brand */
//
/**********************/

/* Libraries */
@import 'animate.css';

/**********************/
/** Colors */
/** Shared */
@import "./spinsoft-core-client-subtree/scss/colors.scss";
/** Brand */
@import "./scss/colors.scss";
/**********************/

/* Shared */
@import "./spinsoft-core-client-subtree/scss/main.scss";
@import "./spinsoft-core-client-subtree/scss/buttons.scss";
@import "./spinsoft-core-client-subtree/scss/modal.scss";

/** Shared Page Styles */
@import './spinsoft-core-client-subtree/pages/game.scss';
@import './spinsoft-core-client-subtree/pages/profile.scss';
@import './spinsoft-core-client-subtree/pages/cashier.scss';
@import './spinsoft-core-client-subtree/pages/registration.scss';
@import './spinsoft-core-client-subtree/pages/verifyEmail.scss';

/** Shared Component Styles */
@import './spinsoft-core-client-subtree/components/navigation.scss';
@import './spinsoft-core-client-subtree/components/navigationInGame.scss';
@import './spinsoft-core-client-subtree/components/errorHandler.scss';
@import './spinsoft-core-client-subtree/components/gameRow.scss';
@import './spinsoft-core-client-subtree/components/gameList.scss';
@import './spinsoft-core-client-subtree/components/gameTile.scss';
@import './spinsoft-core-client-subtree/components/gameFilter.scss';
@import './spinsoft-core-client-subtree/components/loadingScreen.scss';
@import './spinsoft-core-client-subtree/components/gameRowColumnCategory.scss';
@import './spinsoft-core-client-subtree/components/providerRow.scss';
@import './spinsoft-core-client-subtree/components/categoryLinkImage';
@import './spinsoft-core-client-subtree/components/gameRowSpotlightCategory.scss';
@import './spinsoft-core-client-subtree/components/backgroundGradient.scss';
@import './spinsoft-core-client-subtree/components/phoneVerification.scss';
@import './spinsoft-core-client-subtree/components/depositBonusSection.scss';
@import './spinsoft-core-client-subtree/components/depositBonusCard.scss';
@import './spinsoft-core-client-subtree/components/scrollButtons.scss';
@import './spinsoft-core-client-subtree/components/depositToggle.scss';
@import './spinsoft-core-client-subtree/components/depositAmountSection.scss';
@import './spinsoft-core-client-subtree/components/depositButtonBack.scss';
@import './spinsoft-core-client-subtree/components/depositRedirect.scss';
@import './spinsoft-core-client-subtree/components/depositSummary.scss';
@import './spinsoft-core-client-subtree/components/offcanvasBtnClose.scss';
@import './spinsoft-core-client-subtree/components/depositAmountPreset.scss';
@import './spinsoft-core-client-subtree/components/bonusTile.scss';
@import './spinsoft-core-client-subtree/components/bonusRowCategory.scss';
@import './spinsoft-core-client-subtree/components/weeklyBonus.scss';
@import './spinsoft-core-client-subtree/components/promotionRowCategory.scss';
@import './spinsoft-core-client-subtree/components/promotionTile.scss';
@import './spinsoft-core-client-subtree/components/gameRowDouble.scss';
@import './spinsoft-core-client-subtree/components/withdraw.scss';
@import './spinsoft-core-client-subtree/components/gameRowJackpotTypes.scss';
@import './spinsoft-core-client-subtree/components/gameRowLastPlayed.scss';
@import './spinsoft-core-client-subtree/components/freespinsRow.scss';
@import './spinsoft-core-client-subtree/components/registrationForm.scss';
@import './spinsoft-core-client-subtree/components/notification.scss';
@import './spinsoft-core-client-subtree/components/questionMark.scss';
@import './spinsoft-core-client-subtree/components/claimFreeBonus.scss';


/* Brand Component Styles */ 
@import './components/footer.scss';
@import './components/homeBanner.scss';
@import './components/cashbackRoll.scss';
@import './components/googleButton.scss';
@import './components/responsibleGaming.scss';
@import './components/depositMethod.scss';
@import './components/welcomeModal.scss';
@import './components/registrationBanner.scss';
@import './components/depositBanner.scss';


/* Override */
@import './scss/override.scss';


/* Themes */
@import './components/homeBannerTheme.scss';
@import './components/homeBannerThemeOlympus.scss';
@import './components/registrationBannerThemeOlympus.scss';
@import './components/depositBannerThemeOlympus.scss';





