.games-row-columns-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    display: flex;
    flex-direction: row;
    padding-bottom: 3px;
}

.games-row-columns-wrapper>div {
    flex-shrink: 0;
    width: 350px;
    margin-right: 1.25rem;

    @media (max-width: 767px) {
        margin-right: 0.75rem;
        width: 330px;
    }

    @media (max-width: 374px) {
        width: 310px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.games-row-columns-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.games-row-columns-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.game-column {
    background-color: $foreground;
    border-radius: $border-radius;
    padding-bottom: 15px;
    box-shadow: 0px 1px 3px 1px $background-dark;
}

.game-column-top-row {
    display: flex;
    padding: 20px 0 5px 20px;
    justify-content: space-between;
}

.game-column-wrapper {
    position: relative;
    height: 140px;
    text-decoration: none;

    display: flex;
    border: 10px solid transparent;

    margin-left: 10px;
    margin-right: 10px;


    border-radius: $border-radius;

    transition: 0.1s ease;
    -moz-transition: 0.21s ease;
    -o-transition: 0.1s ease;
    -ms-transition: 0.1s ease;
    -webkit-transition: 0.1s ease;

    @media (min-width: 768px) {
        &:hover {
            background-color: $background;
        }
    }

    @media (max-width: 767px) {
        &:active {
            background-color: $background;
        }
    }
}

.game-column-wrapper .tag-container-right {
    top: 0px;
    right: 0px;
}

.game-column-wrapper-placeholder {
    height: 120px;
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: 10px;
}

.game-column-image-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    flex-shrink: 0;
    margin-right: 15px;
}

.game-column-image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    filter: blur(5px);
    border-radius: $border-radius;
    opacity: 0.61;
}

.game-column-image {
    position: relative;
    width: 99px;
    height: auto;
    z-index: 1;
}

.game-column-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.game-column-text-title {
    margin-bottom: 0;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // color: $black;
    color: $font-full;
    font-weight: 600;
    font-size: 0.875rem;

    @media (max-width: 767px) {
        width: 130px;
    }
}

.game-column-text-provider {
    color: $font-low;
    font-weight: 500;
    margin-bottom: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px
}