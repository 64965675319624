.error-handler {
    padding: 5px 15px;
    font-size: 14px;
    color: $error-full;
    border: none;
    position: relative;
    border-radius: 0;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: $error-mid;
        left: 0;
        bottom: -2px;

        -webkit-animation-name: timer;
        animation-name: timer;
        -webkit-animation-duration: 5s;
        animation-duration: 5s;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
    }
}

@keyframes timer {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}