$promo-width: 350px;
$promo-width-mobile: 335px;
$promo-width-mobile-sm: 310px;

.promotion-tile-container {
    display: flex;
    position: relative;
    width: $promo-width;
    background-color: $foreground;
    margin-right: 1.25rem;
    flex-shrink: 0;
    flex-direction: column;
    padding: 0px;
    border-radius: $border-radius;
    box-sizing: content-box;

    @media (max-width: 767px) {
        width: $promo-width-mobile;
        margin-right: 15px;
    }

    @media (max-width: 375px) {
        width: $promo-width-mobile-sm;
    }
}

.featured-promotion-tile-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    background-color: $foreground;
    border-radius: $border-radius;
    // box-shadow: inset 0 0 20px 0px $skin-secondary;
    z-index: 1001;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.promotion-tile-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    flex-grow: 1;

    @media (max-width: 767px) {
        padding: 15px;
        padding-bottom: 20px;
    }
}

.featured-promotion-tile-container .promotion-tile-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 15px 20px;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        align-self: baseline;
        padding: 15px;
        padding-bottom: 20px;
    }
}

.promotion-tile-buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

}

.featured-promotion-tile-container .promotion-tile-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
}

@media (max-width: 767px) {
    .promotion-tile-buttons {
        margin-top: 3px;
    }

    .featured-promotion-tile-container .promotion-tile-buttons {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.promotion-tile-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.featured-promotion-tile-container .promotion-tile-image-wrapper {
    width: auto;
    min-width: 320px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
}

.promotion-tile-image {
    width: 100%;
    height: auto;
    margin-right: 25px;
    border-radius: $border-radius;
    margin: 0px auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-self: flex-start;
}

.featured-promotion-tile-container .promotion-tile-image {
    width: auto;
    height: $promo-image-height;
    border-radius: $border-radius;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    margin: 0px;
    align-self: flex-start;
}

@media (max-width: 767px) {
    .featured-promotion-tile-container .promotion-tile-image {
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        margin: 0px auto;
    }
}

.promotion-tile-badge {
    display: inline-block;
    font-size: 12px;
    color: $primary;
    font-weight: 500;
}