.registration-form {
    text-align: center;
}

// .registration-form-terms {
//     text-align: left;
//     font-size: 26px;

//     label,
//     label a {
//         font-size: 13px;
//     }
// }

.registration-form-terms,
.registration-form-terms a {
    font-size: 13px;
    color: $font-mid;
}

.registration-method-container {
    display: flex;
    flex-direction: column;
}

.registration-method-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.registration-method-separator {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $font-low;
    margin: 20px auto;
    width: 250px;

    &::before {
        content: "";
        position: absolute;
        width: 250px;
        height: 1px;
        background-color: $background-dark;
        left: 0;
        top: 5px;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 12px;
        background-color: $foreground;
        left: calc(50% - 20px);
        top: 0px;
        z-index: -1;
    }
}

.btn-back-registration-form-wrapper {
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.btn-back-registration-form {
    border: none;
    font-size: 12px;
    padding: 7px 20px;
    margin-right: 15px;
}

.btn-register {
    padding: 12px 24px;
    border-radius: 30px;
    // font-weight: 500;
}

//////////////
.registration-verify-email-icon-wrap-success {
    $size: 60px;
    $color: $success;
    
    padding: 10px;
    border-radius: 50%;
    background: $foreground;

    svg {
        display: block;
        height: $size;
        width: $size;
        color: $color;
        /* SVG path use currentColor to inherit this */
    }

    .circle {
        stroke-dasharray: 76;
        stroke-dashoffset: 76;
        animation: draw 1s forwards;
    }

    .tick {
        stroke-dasharray: 18;
        stroke-dashoffset: 18;
        animation: draw 1s forwards 1s;
    }

    @keyframes draw {
        to {
            stroke-dashoffset: 0
        }
    }
}