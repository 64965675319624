@mixin font-settings {
    line-height: 150%;
    letter-spacing: 0.0012em;
    font-style: normal;
}

.title-lg {
    @include font-settings;
    font-weight: 600;
    font-size: 44px;

    @media (max-width: 1199px) {
        font-size: 26px;
    }
}

.title {
    @include font-settings;
    font-weight: 600;
    font-size: 32px;

    @media (max-width: 1199px) {
        font-size: 24px;
    }
}

.title-sm {
    @include font-settings;
    font-weight: 500;
    font-size: 24px;
}

.title-xs {
    @include font-settings;
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 1199px) {
        font-size: 18px;
    }
}

.subtitle-lg {
    @include font-settings;
    font-weight: 500;
    font-size: 21px;

    @media (max-width: 1199px) {
        font-size: 16px;
    }
}

.subtitle {
    @include font-settings;
    font-weight: 500;
    font-size: 18px;

    @media (max-width: 1199px) {
        font-size: 16px;
    }
}

.subtitle-sm {
    @include font-settings;
    font-weight: 500;
    font-size: 16px;
}

.subtitle-xs {
    @include font-settings;
    font-weight: 500;
    font-size: 15px;
}

.tag-lg {
    @include font-settings;
    font-weight: 600;
    font-size: 15px;
}

p, li, a {
    @include font-settings;
    font-size: 0.875rem;
}