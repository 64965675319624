.bonus_roll-container {
    position: relative;

    // background: radial-gradient(circle, $primary 0%, rgba(255, 216, 20, 0) 100%);
    // background-repeat: no-repeat;
    // background-position: 50% 50%;
    // box-shadow: inset 0px 0px 60px 60px $background;
}

.bonus_roll-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    .faq-page-icon {
        margin-top: 1px;
    }
}

.bonus_roll-items_container {
    position: relative;
    max-width: 100%;
    height: 280px;
    perspective: 600px;

    &_mobile-height {
        @media (max-width: 991px) {
            height: 430px;
        }
    }
}

.bonus_roll-attempts_container {
    margin: 0 auto 20px auto;
    text-align: center;

    @media (max-width: 991px) {
        margin: 0 auto 15px auto;
    }

    span {
        font-size: 12px;
        background-color: $background-dark;
        border-radius: $border-radius;
        padding: 3px 7px;
        color: $font-mid;
    }
}

.bonus_roll-timer_container {
    position: absolute;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 0;
}

.bonus_roll-timer_wrapper {
    text-align: center;
    background-color: rgb(255 255 255 / 70%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: $border-radius;
    padding: 30px 20px;
    margin: 10px;
}

.bonus_roll-deposit_container {
    position: absolute;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 0;
}

.bonus_roll-deposit_wrapper {
    text-align: center;
    background-color: rgb(255 255 255 / 70%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: $border-radius;
    padding: 30px 20px;
    margin: 10px;
}

.bonus_roll-arrow {
    position: absolute;
    top: 49px;
    right: calc(50% - 130px);
    width: 40px;
    transform: rotate(322deg);
    z-index: 5;
}

.bonus_roll-background {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 80%;
    background: $foreground;
    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    z-index: -1;
}

.bonus_roll-item-wrapper {
    position: absolute;
    width: 154px;
    height: 235px;
    perspective: 600px;
    transition: all 0.5s ease-in-out;
}

.item-1-wrapper.pos-initial {
    top: 0px;
    left: calc(50% - 80px);
    animation-delay: 0.7s;
}

.item-1-wrapper.pos-default {
    top: 0px;
    left: calc(50% - 80px);

    @media (max-width: 991px) {
        top: 0px;
        left: calc(50% - 80px);
    }
}

.item-2-wrapper.pos-initial {
    top: 20px;
    left: calc(50% - 80px);
    animation-delay: 1s;
}

.item-2-wrapper.pos-default {
    top: 0px;
    left: calc(50% - 350px);

    @media (max-width: 991px) {
        top: 170px;
        left: 2%;
    }
}

.item-3-wrapper.pos-initial {
    top: 40px;
    right: calc(50% - 75px);
    animation-delay: 1.3s;
}

.item-3-wrapper.pos-default {
    top: 0px;
    right: calc(50% - 350px);

    @media (max-width: 991px) {
        top: 190px;
        right: 2%;
    }
}

.bonus_roll-item {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    transform-style: preserve-3d;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
}

// .background-red {
//     background: url(https://cherryspins.b-cdn.net/orozino-test/red-3.png);
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .background-blue {
//     background: url(https://cherryspins.b-cdn.net/orozino-test/blue-3.png);
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .background-purple {
//     background: url(https://cherryspins.b-cdn.net/orozino-test/purple-3.png);
//     background-repeat: no-repeat;
//     background-size: cover;
// }

.bonus_roll-item.item-is-flipped {
    transform: rotateY(180deg);
}

.bonus_roll-item-front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: $border-radius;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.bonus_roll-item-back {
    border-radius: $border-radius;
    transform: rotateY(180deg);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.bonus_roll-item-result {
    transform: rotateY(180deg);
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
    color: rgb(255 255 255 / 40%); // $font-mid;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    margin-bottom: 0px;
    text-align: center;
}

.item-result-text {
    font-size: 15px;
    line-height: 12px;
}

.item-result-currency {
    font-size: 12px;
}

.item-result-winner {
    color: $foreground;
    font-weight: bold;
    text-shadow: 0 0 5px rgb(196 136 43);
    margin-bottom: 15px;
}

.result-is-visible {
    visibility: visible;
}

.animation-shaking {
    -webkit-animation-name: spaceboots;
    animation-name: spaceboots;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}

.animation-floating {
    -webkit-animation-name: float;
    animation-name: float;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;

    &:hover img.bonus_roll-item-front,
    &:hover video.bonus_roll-item-front {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
    }
    
    &:hover img.bonus_roll-item-back,
    &:hover video.bonus_roll-item-back {
        transform: rotateY(180deg);
    }
}

.item-1-wrapper.animation-floating {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
}

.item-3-wrapper.animation-floating {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
}

.item-winner {
    z-index: 10;
}

/////////////////////////
// {
//     -webkit-animation-name: shake;
//     -webkit-animation-duration: 0.8s;
//     -webkit-animation-iteration-count: infinite;
//     -webkit-animation-timing-function: linear;
//     -webkit-transform-origin: 50% 100%;
// }

// {
// -webkit-animation-name: float;
// animation-name: float;
// -webkit-animation-duration: 6s;
// animation-duration: 6s;
// -webkit-animation-iteration-count: infinite;
// animation-iteration-count: infinite;
// -webkit-animation-timing-function: ease-in-out;
// animation-timing-function: ease-in-out;
// -webkit-transform-origin: 50% 100%;
// transform-origin: 50% 100%;
// }
//////////////////////////
/// 
@keyframes shake {
    0% {
        transform: skewX(-15deg);
    }

    5% {
        transform: skewX(15deg);
    }

    10% {
        transform: skewX(-15deg);
    }

    15% {
        transform: skewX(15deg);
    }

    20% {
        transform: skewX(0deg);
    }

    100% {
        transform: skewX(0deg);
    }
}

@keyframes shake1 {
    0% {
        -webkit-transform: translate(2px, 1px) rotate(0deg);
        transform: translate(2px, -10px) rotate(0deg);
    }

    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-2deg);
        transform: translate(-10px, -15px) rotate(-2deg);
    }

    20% {
        -webkit-transform: translate(-3px, 0px) rotate(3deg);
        transform: translate(-3px, -10px) rotate(3deg);
    }

    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
        transform: translate(0px, 2px) rotate(0deg);
    }

    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
        transform: translate(1px, -10px) rotate(1deg);
    }

    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
        transform: translate(-1px, 20px) rotate(-1deg);
    }

    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
        transform: translate(-3px, 10px) rotate(0deg);
    }

    70% {
        -webkit-transform: translate(2px, 1px) rotate(-2deg);
        transform: translate(2px, 1px) rotate(-2deg);
    }

    80% {
        -webkit-transform: translate(-1px, -1px) rotate(4deg);
        transform: translate(-1px, -10px) rotate(4deg);
    }

    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
        transform: translate(2px, 10px) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
        transform: translate(1px, 0px) rotate(-1deg);
    }
}

@keyframes spaceboots {
    0% {
        transform: translate(2px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(0px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(2px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(2px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes float {
    0% {
        // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }

    50% {
        // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(20px);
    }

    100% {
        // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

/////////////////////////
.animate__animated.animate__flipCustom {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flipCustom;
    animation-name: flipCustom;
}

@keyframes flipCustom {
    from {
        -webkit-transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(1300px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(1300px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    to {
        -webkit-transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        transform: perspective(1300px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}