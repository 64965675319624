/***************************/
/* Profile Balance*/
.profile-balance {
    margin: 0px 15px 15px;
    position: relative;
    display: block;

    @media (max-width: 991px) {
        margin: 0 10px 15px;
    }
}

.profile-main {
    margin-bottom: 15px;
}

.profile-email {
    font-size: 14px;
    color: $font-low;
}

.profile-balance-title {
    font-weight: 600;
    color: $font-mid;
}

.profile-balance-main {
    padding: 20px 15px 20px;
 
    @media (max-width: 991px) {
        padding: 20px 10px 20px;
    }
}

.profile-balance-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile-balance-item span,
.profile-bonus-item span {
    font-weight: 500;
}

.profile-bonus {
    position: relative;
    display: block;
    background-color: $background;
    border-radius: $border-radius;
    padding: 20px;

    @media (max-width: 991px) {
        padding: 15px 10px;
    }
}

.profile-bonus-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile-bonus-wager {
    padding: 10px 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: $foreground;
    border-radius: $border-radius;
    box-shadow: 0px 1px 3px 1px $background-dark;
}

.profile-bonus-wager-title {
    margin-bottom: 10px;
    font-size: 13px;
}

.profile-bonus-wager-bar {
    margin-bottom: 5px;
}

.profile-bonus-wager-value {
    font-size: 13px;
    margin-bottom: 0px;
    text-align: right;
}

.progress-bar {
    background-color: $primary;
}

.profile-bonus-expiry-date {
    font-size: 12px;
    color: $font-low;
    margin-bottom: 0px;
    text-align: right;
}

.profile-bonus-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

/***************************/
/* Profile Support*/
.profile-support {
    padding: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px 20px;

    @media (max-width: 991px) {
        margin: 0 10px 25px;
    }
}

.profile-support-button {
    width: 100%
}

/***************************/
/* Profile Information */
.profile-information {
    // padding: 30px 15px 15px;
    padding: 10px 15px 0px;
    // background: $background;
    margin-bottom: 30px;
    // border-bottom: 1px solid $background-dark;

    @media (max-width: 991px) {
        margin-bottom: 20px;
        padding: 10px 10px 0px;
    }
}

.profile-information-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.profile-information-title {
    font-weight: 600;
    color: $font-mid;
    padding-left: 15px;
    margin-bottom: 0;

    @media (max-width: 991px) {
        padding-left: 0px;
    }
}

.profile-information .row {
    --bs-gutter-x: 0 !important;
}

.profile-information-button {
    font-size: 22px;
    padding: 7px 25px;
    margin-left: 10px;
    line-height: 22px;
}

// /***************************/
// /* Profile Notification */
// .profile-loyalty-notification {
//     //@include section-properties;

//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     background: $primary;
//     border-radius: $border-radius;
//     // border: 2px solid $background;
// }

// .profile-loyalty-notification-button {
//     white-space:nowrap;
//     margin-left: 15px;
// }

/***************************/
/* Profile Notification */
.profile-logout {
    padding: 30px 15px 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-top: 1px solid $background-dark;

    @media (max-width: 991px) {
        padding: 20px 10px;
    }
    
}

.profile-logout-button {
    width: 100%;
    white-space: nowrap;
}

/***************************/
/* Profile Notification */
.profile-close-account {
    position: relative;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    background: $background;
    border-radius: $border-radius;
    padding: 20px;
    margin: 0px 15px;

    @media (max-width: 991px) {
        margin: 0 10px;
        padding: 15px 10px;
    }
}

.profile-close-account-button {
    // width: 100%;
    white-space: nowrap;
}

.profile-bottom-space {
    padding-bottom: 70px;
}