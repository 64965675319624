.providers-row-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    order: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.providers-row-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.providers-row-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}