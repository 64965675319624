.btn-deposit-back-container {
    position: absolute;
    bottom: 55px;
    display: flex;
    padding: 11px 15px 17px;
    width: 100%;
    justify-content: start;
    background: $foreground;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
    border-radius: $border-radius $border-radius 0 0;

    &-app {
        padding: 5px 15px 30px;
    }
}

.btn-deposit-back {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 43px;
    padding: 15px 0 20px;
    width: 100%;
    // font-size: 20px;

    color: $font-mid;
    background: $foreground;
    border-radius: $border-radius;

    outline: none;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        outline: none;
        border: none;
        box-shadow: none !important;
        background: $foreground;
        color: $font-full;
    }

    &:active {
        transform: scale(0.95);
    }
}