.responsible-gaming-wrapper {
    display: flex;
    align-items: center;
    background: $foreground;
    border-radius: $border-radius;
    box-shadow: 0px 1px 3px 1px $background-dark;
    padding: 10px;

    @media (max-width: 991px) {
        padding-bottom: 25px;
        flex-direction: column-reverse;
    }
}

.responsible-gaming-content-wrapper {
    flex-basis: 50%;
    flex-shrink: 0;
    padding: 10px;
    text-align: center;

    @media (max-width: 991px) {
        flex-basis: 100%;
        // padding-right: 0px;
        padding: 0;
        margin-top: 10px;
    }
}

.responsible-gaming-image-wrapper {
    flex-basis: 50%;
    flex-shrink: 0;
    text-align: right;

    @media (max-width: 991px) {
        flex-basis: 100%;
    }
}

.responsible-gaming-image {
    width: 100%;
    height: auto;
    // max-height: 200px;
    // width: auto;
    border-radius: calc($border-radius - 5px);

    // @media (max-width: 991px) {
    //     width: 100%;
    //     height: auto;
    // }
}

.responsible-gaming-logos {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;

    @media (max-width: 991px) {
        margin-top: 30px;
        justify-content: space-evenly;
    }
}

.responsible-gaming-logo {
    height: 40px;
    margin-right: 50px;

    @media (max-width: 991px) {
        margin-right: 0px;
    }
}