@mixin section-properties {
    margin: 0px 15px 15px;

    @media (max-width: 991px) {
        margin: 0 10px 15px;
    }
}

// .withdraw-container {
//     padding-top: 10px;
// }

.withdraw-balance  {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 15px;

    @media (max-width: 991px) {
        margin-top: 10px;
    }
}

// .withdraw-section {
//     @include section-properties;
// }

.withraw-amount {
    margin: 30px 15px 15px;

    @media (max-width: 991px) {
        margin: 25px 10px 15px;
    }
}

.withdraw-method,
.withdraw-redirect {
    @include section-properties;
}

.withdraw-method {
    padding-bottom: 90px;
}

.withdraw-redirect {
    padding-top: 15px;
    padding-bottom: 150px;

    @media (max-width: 991px) {
        padding-top: 10px;
    }
}

.withdraw-redirect .row {
    --bs-gutter-x: 0 !important;
}

.withdraw-bonus {
    position: relative;
    max-width: 300px;
    background: $background;
    padding: 15px;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.withdraw-bonus-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.withdraw-bonus-item-label {
    font-size: 13px;
}

.withdraw-bonus-question-mark-container {
    position: absolute;
    right: -15px;
    top: -10px;
}