.deposit_banner_background {
    width: 100%;
    position: absolute;
    z-index: -1;
    margin-top: -65px;

    @media (max-width: 768px) {
        margin-top: -55px;
    }
}

.deposit_banner_image {
    width: 150px;
    position: relative;
    float: left;
    z-index: -1;
    margin-left: 10px;

    @media (max-width: 768px) {
        width: 140px;
        margin-left: 0px;
    }
}

.deposit_banner_arrow {
    transform: rotate(87deg);
    right: 60%;
    top: 120px;
    height: 67px;
    position: absolute;
    z-index: 1;

    @media (max-width: 768px) {
        transform: rotate(77deg);
        right: 58%;
        top: 108px;
        height: 54px;
    }
}

.deposit_banner_container {
    margin-bottom: 10px;

    @media (max-width: 768px) {
        margin: 0px 0 15px;
    }
}

.deposit_banner_content {
    margin-left: auto;
    position: relative;
    text-align: right;
    width: 65%;

    @media (max-width: 768px) {
        width: 68%;
    }
}

.deposit_banner_content_background {
    -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    -webkit-mask-size: 110% 100%;
    mask-size: 110% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 30%;
    mask-position: 30%;
    white-space: nowrap;
    background-color: $font-full;
    padding: 20px 50px 10px 0px;
    margin-top: -17px;

    @media (max-width: 768px) {
        padding: 15px 30px 10px 10px;
        margin-top: -13px;
    }
}

.deposit_banner_content_title {
    font-size: 30px;
    color: $primary;
    font-weight: 700;
    margin-bottom: 0;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.deposit_banner_content_subtitle {
    font-size: 14px;
    color: #FFF;
    margin-bottom: 0;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.deposit_banner-timer-wrapper {
    position: relative;
    display: inline-block;
    margin: 0;
    margin-right: 15px;
    font-size: 11px;
    background: $error-full;
    color: #FFF;
    font-family: 'Roboto Mono', monospace;
    padding: 1px 10px 1px 5px;
    border-radius: $border-radius;
    font-weight: 500;
    z-index: 2;

    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-size: 105% 100%;
    mask-repeat: no-repeat;
    mask-position: 30%;
    white-space: nowrap;

    padding: 3px 15px 3px 15px;

    @media (max-width: 768px) {
        margin-right: 5px;
    }
}




















// .deposit_banner_container {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     position: relative;
//     // border: 1px solid $primary; //$background-dark;
//     background: $foreground; // background; // $;
//     border-radius: $border-radius;
//     z-index: 1001;
//     margin: 0 15px 2px;
//     padding: 0px;
//     box-shadow: 0px 1px 3px 1px rgb(225, 225, 225);

//     & > img {
//         height: 80px;
//         border-radius: $border-radius 0 0 $border-radius;

//         @media (max-width: 767px) {
//             height: 65px;
//         }
//     }

//     // background-image: url(https://spt-assets.b-cdn.net/orozino/assets/secure-logos.png);
//     // background-repeat: no-repeat;
//     // background-position: right;
//     // background-size: 155px;
//     // height: 92px;

//     @media (max-width: 767px) {
//         margin: 0 10px 2px;
//         // justify-content: space-between;
//     }
// }

// .deposit_banner_content {
//     // text-align: center;
//     margin-left: 10px;

//     @media (max-width: 767px) {
//         margin-left: 0px;
//     }
// }

// .deposit_banner_content_badge {
//     font-weight: 600;
//     font-size: 10px;

//     @media (max-width: 767px) {
//         font-size: 9px;
//     }
// }

// .deposit_banner_content_title {
//     font-weight: 500;
//     font-size: 15px;
//     margin-bottom: 2px;

//     @media (max-width: 767px) {
//         font-size: 13px;
//         margin-bottom: 2px;
//     }
// }

// .deposit_banner_content_subtitle {
//     font-size: 11px;

//     @media (max-width: 767px) {
//         font-size: 9px;
//     }
// }

// .deposit_banner_content_money {
//     font-weight: 600;
//     font-size: 26px;

//     display: inline;
//     mask-image: url(https://i.imgur.com/P1bFwVG.png);
//     mask-size: 105% 100%;
//     mask-repeat: no-repeat;
//     mask-position: 30%;
//     white-space: nowrap;
//     background-color: $primary;
//     color: $font-full;
//     padding: 0 15px;
//     margin-left: -5px;
//     margin-right: -2px;

//     @media (max-width: 767px) {
//         font-size: 22px;
//     }
// }

// .deposit_banner_images {
//     display: flex;
//     width: 100%;
//     justify-content: space-around;

//     @media (max-width: 767px) {
//         justify-content: space-between;
//     }

//     &>* {
//         height: 30px;
//     }
// }

// .deposit_banner_image {
//     height: 30px;
//     margin: auto;
// }

// .deposit_banner_arrow {
//     position: absolute;
//     height: 35px;
//     bottom: -36px;
//     right: 86px;
//     transform: rotate(20deg);

//     @media (max-width: 767px) {
//         height: 35px;
//         bottom: -32px;
//         right: 16px;
//     }
// }



// .deposit_banner-question-mark-wrapper {
//     position: absolute;
//     right: 10px;
//     bottom: 7px;
// }