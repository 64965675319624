/***************************/
/* Variables */
$border-radius: 17px;

/***************************/
/* Mixins */
@mixin category-link-hover {
    transition: all 0.1s ease-in;

    &:hover {
        background-color: $foreground;
    }
}

/***************************/
/* Body */
* {
    -webkit-overflow-scrolling: touch;
}

body {
    position: relative;
    background-color: $background;
}

body.modal-open {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
}

/***************************/
/* Scrollbars */
/* Hide scrollbar for Chrome, Safari and Opera */
.game-row-wrapper::-webkit-scrollbar,
.spotlight-row-wrapper::-webkit-scrollbar,
.provider-row-wrapper::-webkit-scrollbar,
.bonus-card-row-wrapper::-webkit-scrollbar,
.bonus-row-wrapper::-webkit-scrollbar,
.promotions-row-container::-webkit-scrollbar,
.game-row-double-wrapper::-webkit-scrollbar,
.games-filter::-webkit-scrollbar,
.game-row-last-played-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.game-row-wrapper,
.spotlight-row-wrapper,
.provider-row-wrapper,
.bonus-card-row-wrapper,
.bonus-row-wrapper,
.promotions-row-container,
.game-row-double-wrapper,
.games-filter,
.game-row-last-played-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/***************************/
/** Containers */
.category-container {
    position: relative;
    margin-top: 30px;

    @media (max-width: 575px) {
        margin-top: 20px;
    }
}

@media (max-width: 991px) {
    .row-container {
        padding-right: 0;
    }
}

.section-margin {
    margin-bottom: 40px;

    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
}

.lazy-loader-container {
    position: relative;
}

.lazy-loader {
    position: absolute;
    bottom: 0px;
    padding-top: 200px;
    padding-bottom: 200px;
    z-index: -1;
}

/***************************/
/** Inputs */
.form-control {
    background-color: $foreground;
    border-color: $background-dark;
    padding: 16px;
    border-radius: $border-radius !important;
    color: $font-full;
}

.form-control:focus {
    background-color: $foreground;
    color: $font-full;
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .was-validated .form-control:invalid {
    background-position: right calc(1em + 0.1875rem) center;
}

.form-control:disabled, .form-control[readonly] {
    // background-color: $background-dark;
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.75rem + 2px);
    padding-left: 20px;
    padding-right: 20px;
}

.form-floating>label {
    padding: 1.125rem;
    padding-left: 20px;
    padding-right: 0px;
    color: $font-mid;
}

.form-check-input {
    border-color: $background-dark;
}

////////////////////////
/** Hiding chat button */
.bb-feedback-button {
    display: none !important;
}
