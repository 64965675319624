$title_color: #539d57;

.welcome_modal {
    .modal-content {
        background-color: transparent;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
    }

    .modal-body {
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .welcome_modal_timer_wrapper {
        position: absolute;
        right: 27px;
        bottom: 7px;
        margin: 0;
        font-size: 11px;
        background: $background-dark;
        font-family: 'Roboto Mono', monospace;
        padding: 1px 10px 1px 5px;
        border-radius: $border-radius;
        font-weight: 500;
    
        mask-image: url(https://i.imgur.com/P1bFwVG.png);
        mask-size: 105% 100%;
        mask-repeat: no-repeat;
        mask-position: 30%;
        white-space: nowrap;
    
        padding: 3px 15px 3px 15px;
    }

    .welcome_modal_timer_wrapper {
        right: auto;
        font-size: 16px;
        background-color: #d93744;
        color: #FFF;

        @media (max-width: 767px) {
            font-size: 13px; 
        }
    }
}

.welcome_modal_image {
    width: 100%;
    max-width: 450px;
}

.welcome_modal_button,
.welcome_modal_button:hover,
.welcome_modal_button:active {
    position: absolute;
    bottom: 13%;
    appearance: none;
    background-color: #FFFFFF;
    border-radius: 40em;
    border-style: none;
    font-size: 24px;
    font-weight: 700;
    padding: 1.5rem 3rem;
    color: #FFF;
    background: linear-gradient(0deg, #21ae48 0%, #33db41 100%);
    box-shadow: #2bb50d 0px -8px 8px inset, #5ae53f 0px 8px 8px inset;
    text-shadow: 0px 1px rgb(0 0 0 / 70%);

    @media (max-width: 767px) {
        font-size: 20px;
        padding: 20px 35px;
        bottom: 12%;
    }
}