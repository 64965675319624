/*********************/
/* Mixins */
@mixin btn {
    font-size: 14px;
    line-height: 14px;

    font-weight: 600;
    padding: 15px 30px;

    text-decoration: none;
    
    border: 1px solid transparent;
    border-radius: $border-radius;

    transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    -ms-transition: 0.2s ease;
    -webkit-transition: 0.2s ease;

    .spinner-border {
        width: 14px !important;
        height: 14px !important;
    }

    @media (max-width: 991px) {
        padding: 15px 20px;
    }
}

@mixin btn-lg {
    @include btn;
    padding: 20px 30px;

    @media (max-width: 991px) {
        padding: 20px;
    }
}

@mixin btn-color($color, $background) {
    color: $color;
    background: $background;
    border-color: transparent;
}

@mixin action($color, $background) {
    color: $color;
    background: $background;
    border-color: transparent;

    text-decoration: none;
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent;
}

@mixin active {
    transform: scale(0.96);
}

@mixin disabled($color, $background) {
    @include btn-color($color, $background);

    opacity: 0.65;
}

/*********************/
/* Main button */
.btn-main,
.btn-main-lg {
    @include btn;
    @include btn-color($font-full, $primary);
    // box-shadow: 0px 1px 0px $primary-dark;

    &:focus {
        @include action($font-full, $primary);
        // box-shadow: 0px 1px 0px $primary-dark !important;
    }

    &:hover {
        @include action($font-full, $primary);
        // box-shadow: 0px 1px 0px $primary-dark !important;
    }

    &:active {
        @include action($font-full, $primary);
        @include active;
        // box-shadow: 0px 1px 0px $primary-dark;
    }

    &:disabled {
        @include disabled($font-full, $primary)
    }
}

.btn-main-lg {
    @include btn-lg;
}

/*********************/
/* Main button with outline */
.btn-main-outline,
.btn-main-outline-lg {
    @include btn;
    @include btn-color($font-full, transparent);
    background-color: transparent;
    border-color: $primary;

    &:focus {
        background-color: transparent;
        border-color: $primary;
    }

    &:hover {
        @include action($font-full, transparent);
        border-color: $primary;
    }

    &:active {
        @include action($font-full, transparent);
        @include active;
        border-color: $primary;
    }

    &:disabled {
        @include disabled($font-low, transparent);
        border-color: $background-dark;
    }
}

.btn-secondary-outline-lg {
    @include btn-lg;
}

/*********************/
/* Secondary button */
.btn-secondary,
.btn-secondary-lg {
    @include btn;
    @include btn-color($font-mid, $background);

    &:focus {
        @include action($font-mid, $background);
    }

    &:hover {
        @include action($font-full, $background);
    }

    &:active {
        @include action($font-full, $background);
        @include active
    }

    &:disabled {
        @include disabled($font-low, $background)
    }
}

.btn-secondary-lg {
    @include btn-lg;
}

/*********************/
/* Secondary button with outline */
.btn-secondary-outline,
.btn-secondary-outline-lg {
    @include btn;
    @include btn-color($font-mid, transparent);
    background-color: transparent;
    border-color: $background-dark;

    &:focus {
        background-color: transparent;
        border-color: $background-dark;
    }

    &:hover {
        @include action($font-full, transparent);
        border-color: $background-dark;
    }

    &:active {
        @include action($font-full, transparent);
        @include active;
        border-color: $background-dark;
    }

    &:disabled {
        @include disabled($font-low, transparent);
        border-color: $background-dark;
    }
}

.btn-secondary-outline-lg {
    @include btn-lg;
}

/*********************/
/* Danger button */
.btn-danger,
.btn-danger-lg {
    @include btn;
    @include btn-color($white, $error-full);

    &:focus {
        @include action($white, $error-full);
    }

    &:hover {
        @include action($white, $error-full);
    }

    &:active {
        @include action($white, $error-full);
        @include active
    }

    &:disabled {
        @include disabled($white, $error-full)
    }
}

.btn-danger-lg {
    @include btn-lg;
}

/*********************/
/* Danger button */
.btn-alert,
.btn-alert-lg {
    @include btn;
    @include btn-color($error-full, $error-low);

    &:focus {
        @include action($error-full, $error-low);
    }

    &:hover {
        @include action($error-full, $error-low);
    }

    &:active {
        @include action($error-full, $error-low);
        @include active
    }

    &:disabled {
        @include disabled($error-full, $error-low)
    }
}

.btn-alert-lg {
    @include btn-lg;
}