.bonus-tile-timer-wrapper,
.weekly-bonus-timer-wrapper,
.bonus-card-timer-wrapper {
    -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    -webkit-mask-size: 105% 100%;
    mask-size: 105% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 30%;
    mask-position: 30%;
    white-space: nowrap;
}

.verify-phone-title-spins {
    -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    -webkit-mask-size: 105% 100%;
    mask-size: 105% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 30%;
    mask-position: 30%;
    white-space: nowrap;
    background-color: $font-full;
    color: $primary;
    padding: 0 15px;
    margin-left: -5px;
    margin-right: -2px;
}

.bonus-tile-timer-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
    top: -2px;
}

.weekly-bonus-timer-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
    top: -9px;
}

.bonus-card-timer-wrapper {
    padding: 3px 15px 3px 15px;
    top: -2px;
}

/* Jackpots */
.daily-drop  {
    background-image: url(https://cherryspins.b-cdn.net/orozino-test/jackpot-daily-14.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.hourly-drop  {
    background-image: url(https://cherryspins.b-cdn.net/orozino-test/jackpot-daily-15.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.must-drop-title-daily,
.must-drop-title-hourly {
    color: $primary;
    font-size: 32px;
}