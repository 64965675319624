.notification-timer {
    position: relative;
    
    .toast-body,
    .toast-header {
        background: $foreground;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: $primary;
        left: 0;
        bottom: 0px;

        -webkit-animation-name: timer;
        animation-name: timer;
        -webkit-animation-duration: 5.05s;
        animation-duration: 5.05s;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}

@keyframes timer {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}