.bonus-card-placeholder {
    background: $background;
    padding: 0px;
    border: none;

    .placeholder {
        border-radius: $border-radius;
        height: 100%;
    }
}

.bonus-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px 5px 0 5px;
    text-align: left;
    
    background: $foreground; // $background;

    border: 0px solid transparent;
    box-shadow: 0px 1px 3px 1px $background-dark;
    border-radius: $border-radius;
    // transition: all 0.1s ease-out;
}

.bonus-card:hover,
.bonus-card:active,
.bonus-card:focus {
    outline: none !important;
    
    background: $background;

    border: 0px solid transparent;
    box-shadow: 0px 1px 3px 1px $background-dark !important;
}

.bonus-card-selected,
.bonus-card-selected:hover,
.bonus-card-selected:active,
.bonus-card-selected:focus {
    outline: none !important;
    // border: 2px solid $primary;
    background: $primary;
    // box-shadow: 0px 1px 3px 1px $background-dark;
    // box-shadow: none !important;
}

.bonus-card img {
    width: 100%;
    border-radius: calc($border-radius - 5px);
}

.bonus-card-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 8px 10px 10px 10px;
}

.bonus-card-title {
    color: $font-full;
    font-weight: 500;
    margin-bottom: 0px;
}

.bonus-card-badge {
    font-size: 12px;
    margin-bottom: 0px;
    color: $font-mid;
}

.bonus-card-details {
    font-size: 12px;
    margin-bottom: 0px;
    color: $font-mid;
    margin-top: 10px;
}

.btn-bonus-select {
    margin: 0px 10px 10px 10px;
}

.icon-bonus-selected {
    font-size: 18px;
}

.bonus-card-check {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 12px 6px 0;
}

.bonus-card-check svg {
    height: 18px;
    color: $foreground;
}

.bonus-card-timer-wrapper {
    position: absolute;
    left: calc($border-radius - 5px);
    top: 0px;
    margin: 0;
    font-size: 11px;
    background: $error-full;
    font-family: 'Roboto Mono', monospace;
    padding: 1px 10px 1px 5px;
    border-radius: $border-radius;
    font-weight: 500;
}