.must-drop-jackpot-row-wrapper {
    grid-template-columns: 300px 210px;
    padding-bottom: 3px;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-template-columns: 300px 195px;
    }

    @media (max-width: 767px) {
        grid-template-columns: 290px 175px;
    }
}

.title-placeholder {
    @media (min-width: 992px) {
        margin-top: 60px;
    }
}

.must-drop-jackpot-tile-wrapper {
    display: flex;
    align-items: center;
    gap: 7px;

    .faq-page-icon {
        margin-top: 1px;
    }
}

.must-drop-jackpot-tile-container {
    display: flex;
    flex-direction: column;
    background-color: $foreground;
    justify-content: flex-start;
    border-radius: $border-radius;
    padding: 25px;
    box-shadow: 0px 1px 3px 1px $background-dark;

    @media (max-width: 992px) {
        padding: 15px;
    }
}

.must-drop-content-top {
    align-self: center;
    text-align: center;
    margin-bottom: 39px;
    color: #FFF;

    @media (max-width: 768px) {
        margin-bottom: 33px;
    }
}

.must-drop-content-bottom {
    align-self: center;
    position: relative;
    text-align: right;
}

// .daily-drop {
//     background: linear-gradient(135.31deg, #272727 55%, #0D7077 100%);
// }

// .hourly-drop {
//     background: linear-gradient(135.31deg, #272727 55%, #9139D7 100%);
// }

.must-drop-timer-lable {
    // font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: #FFF;
}

.must-drop-timer {
    font-weight: 500;
    font-size: 19px;
    color: #FFF;
    font-family: 'Roboto Mono', monospace;
    // text-align: left;
    // margin-left: auto;
    // margin-right: -5px;
}

