/**************************/
/** Main colors */
$primary: #ffd814;
$primary-dark: #ffbe00;
$primary-alt: #FF8F00;

/**************************/
/** Background colors */
$background-dark: rgb(225 225 225);
$background: rgb(240 240 240);
$foreground: rgb(255 255 255);

/**************************/
/** Maintanace colors */
$error-full: rgb(220 53 69 / 100%);
$error-mid: rgb(220 53 69 / 50%);
$error-low: rgb(220 53 69 / 20%);

$success-full: rgb(0 128 0 / 100%);
$success-mid: rgb(0 128 0 / 50%);
$success-low: rgb(0 128 0 / 20%);

/**************************/
/** Font colors */
$font-full: rgb(27 23 1 / 100%);
$font-mid: rgb(27 23 1 / 70%);
$font-low: rgb(27 23 1 / 40%);

/**************************/
/** Color classes */
.font-full {
    color: $font-full;
}

.font-mid {
    color: $font-mid;
}

.font-low {
    color: $font-low;
}

.error-full {
    color: $error-full;
}

.success {
    color: $success;
}

.primary {
    color: $primary;
}

.primary-alt {
    color: $primary-alt;
}