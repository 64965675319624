.game-tile-container {
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.game-tile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    border-radius: $border-radius;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.1s ease;
    -moz-transition: 0.21s ease;
    -o-transition: 0.1s ease;
    -ms-transition: 0.1s ease;
    -webkit-transition: 0.1s ease;
}

@media (hover: hover) and (pointer: fine) {
    .game-tile-container:hover .game-tile-overlay {
        // animation-name: blurAnimationIn;
        // animation-iteration-count: 1;
        // animation-timing-function: ease-in;
        // animation-duration: 0.1s;
        opacity: 1;
        // backdrop-filter: blur(1px);
        // -webkit-backdrop-filter: blur(1px);
    }
}

// @keyframes blurAnimationIn {
// 	0% { 
//         opacity: 0;
//         backdrop-filter: blur(0px);
//         -webkit-backdrop-filter: blur(0px);
//     }
// 	100% { 
//         opacity: 1;
//         backdrop-filter: blur(1px);
//         -webkit-backdrop-filter: blur(1px);
//     }
// }

.game-tile-overlay-icon {
    color: $white;
    opacity: 0.9;
    font-size: 3rem;
}

.game-tile-image-wrapper {
    position: relative;
    opacity: 1;
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
    flex-grow: 1;
    overflow: hidden;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
}

.game-tile-image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    filter: blur(5px);
    border-radius: $border-radius;
    opacity: 0.61;
}

.game-tile-image {
    width: 100%;
    height: auto;
    // background: $dp02;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.game-tile-placeholder-wrapper {
    display: flex;
    flex-direction: column;
}

.game-tile-image-placeholder-wrapper {
    height: 100%;
}

.game-tile-image-placeholder {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    flex-grow: 1;
}

.game-tile-title {
    color: $font-full;
    font-weight: 600;
    margin: 0.375rem 0 0;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.game-tile-provider {
    color: $font-low;
    font-size: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 575px) {
    .game-tile-title {
        font-size: 0.875rem;
    }

    .game-tile-provider {
        font-size: 0.75rem;
    }
}

.tag-container-right,
.tag-container-left {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 1px 6px;
    background-color: rgba(0,0,0,0.7);
    border-radius: $border-radius;
    display: flex;
    align-items: center;
}

.tag-container-left {
    left: 7px;
    right: auto;
}

.tag-container-bottom-center {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    width: 90%;
    margin: auto;
    padding: 4px 6px;
    background-color: rgba(0,0,0,0.7);
    border-radius: $border-radius;
    text-align: center;
    font-size: 14px;
    color: $white;
    font-weight: 600;
}

.tag-image {
    width: 17px;
    height: auto;
    margin-right: 5px;
}

.tag-name {
    font-size: 12px;
    color: $white;
    font-weight: 500;
}

.img-live {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: rgb(72, 178, 128);
    margin-right: 7px;

    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
    50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}