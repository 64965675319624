.deposit_amount_section {
    padding: 0 15px 90px;

    @media (max-width: 991px) {
        padding: 0 10px 90px;
    }
}

.deposit_amount_section_title {
    margin-bottom: 10px;
    color: $font-mid;
}

.btn-deposit {
    margin-top: 15px;
    position: relative;
    width: 100%;
}

.deposit_amount_section_images {
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;

    &>* {
        height: 32px;
    }
}