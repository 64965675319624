.verify-email-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    justify-content: center;
    align-items: center;
}

.verify-email-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verify-email-icon-wrap-success {
    $size: 100px;
    $color: $success;
    
    padding: 10px;
    border-radius: 50%;
    background: $foreground;

    svg {
        display: block;
        height: $size;
        width: $size;
        color: $color;
        /* SVG path use currentColor to inherit this */
    }

    .circle {
        stroke-dasharray: 76;
        stroke-dashoffset: 76;
        animation: draw 1s forwards;
    }

    .tick {
        stroke-dasharray: 18;
        stroke-dashoffset: 18;
        animation: draw 1s forwards 1s;
    }

    @keyframes draw {
        to {
            stroke-dashoffset: 0
        }
    }
}

.verify-email-icon-wrap-fail {
    $size: 100px;
    $color: $error-full;
    padding: 10px;
    border-radius: 50%;
    background: $foreground;

    svg {
        display: block;
        height: $size;
        width: $size;
        color: $color;
        /* SVG path use currentColor to inherit this */
    }
}


