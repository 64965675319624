.offcanvas-registration-section {
    padding: 15px;

    @media (max-width: 768px) {
        padding: 10px;
    }

    .btn-back-registration-form-wrapper {
        margin-top: -5px;
    }

    .offcanvas-registration-form-wrapper {
        background-color: rgb(255 255 255 / 90%);
        border-radius: calc($border-radius - 5px);
        padding: 30px 30px 20px;
        margin: 0 50px;

        @media (max-width: 768px) {
            padding: 35px 15px 20px;
            margin: 0 10px;
        }
    }
}