.home_banner_container_theme {
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    border-radius: $border-radius;
    padding: 0;

    background-image: url(https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/home_banner_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -100px;

    @media (max-width: 1199px) {
        background-position-y: 0;
    }
}

.home_banner_content_wrapper_theme {
    position: relative;
    z-index: 10;
    height: 560px;
    display: flex;
    width: 100%;

    @media (max-width: 1399px) {
        height: 515px;
    }
}

.home_banner_images_theme {
    z-index: -1;
    @media (max-width: 768px) {
        margin-top: 20px;
        height: 250px;
        margin-bottom: -70px;
    }
}

.home_banner_content_theme {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }
}

.home_banner_content_background_theme {
    text-align: center;
    -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    -webkit-mask-size: 105% 100%;
    mask-size: 105% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 30%;
    mask-position: 30%;
    white-space: nowrap;
    background-color: $font-full;
    padding: 35px 60px;

    @media (max-width: 768px) {
        padding: 25px 40px;
    }
}

.surtile_theme {
    color: #FFF;
}

.title_theme {
    color: $primary;
    font-weight: 700;

    @media (min-width: 1200px) {
        font-size: 45px;
    }
}

.subtitle_theme {
    color: #FFF;
}

// .home_banner-content>div {
//     margin: 0 10px;

//     @media (max-width: 768px) {
//         margin: 0;
//     }
// }

// .home_banner-offer-wrapper {
//     position: relative;

//     @media (max-width: 768px) {
//         padding: 60px 0 50px;
//         text-align: center;
//     }

//     @media (max-width: 359px) {
//         padding: 30px 0 30px;
//     }

//     &::before {
//         content: "";
//         width: 100%;
//         height: 180px;
//         background: rgb(213 244 255 / 75%);
//         position: absolute;
//         top: -20px;
//         left: 0;
//         z-index: -1;
//         -webkit-filter: blur(40px);
//         filter: blur(40px);

//         @media (max-width: 768px) {
//             content: "";
//             width: 100%;
//             height: 100px;
//             background: rgba(166, 235, 255, 0.75);
//             position: absolute;
//             top: 35px;
//             left: 0;
//             z-index: -1;
//             filter: blur(40px);
//         }
//     }
// }

// .home_banner-form-container {
//     width: 450px;
//     height: 400px;
//     display: flex;
//     align-items: center;

//     @media (max-width: 768px) {
//         padding: 10px;
//         width: 100%;
//         height: auto;
//     }
// }

// .home_banner-form-wrapper {
//     width: 100%;
//     background-color: rgb(255 255 255 / 95%);
//     border-radius: calc($border-radius - 5px);
//     padding: 40px 30px 20px;

//     @media (max-width: 768px) {
//         padding: 35px 15px 20px;
//         background-color: rgb(255 255 255);
//     }
// }

// .home_banner-welcome-wrapper {
//     width: 100%;
//     background-color: rgb(255 255 255 / 95%);
//     border-radius: $border-radius;
//     padding: 30px;

//     @media (max-width: 768px) {
//         padding: 40px 20px;
//         background-color: rgb(255 255 255);
//     }
// }

// .home_banner-form-title {
//     margin-bottom: 13px;
//     font-weight: 600;
// }

// .home_baner-promo_font {
//     font-weight: 600;
//     position: relative;
//     white-space: nowrap;

//     &:before {
//         content: "";
//         width: calc(125%);
//         height: 100%;
//         position: absolute;
//         z-index: -1;
//         top: 0;
//         left: -13%;
//         font-style: normal;
//         background-color: $primary;
//         background-position: 0 43%;
//         background-size: cover;
//         -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
//         mask-image: url(https://i.imgur.com/P1bFwVG.png);
//         -webkit-mask-size: 100% 100%;
//         mask-size: 100% 100%;
//         -webkit-mask-repeat: no-repeat;
//         mask-repeat: no-repeat;
//     }
// }

// .home_banner-left_tree {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 200px;
//     height: 500px;
//     z-index: 4;

//     &_image {
//         width: 100%;
//         height: auto;
//         position: absolute;
//         left: 0;
//         bottom: 0;

//         @media (max-width: 1399px) {
//             left: -30px;
//         }

//         @media (max-width: 1199px) {
//             left: -70px;
//         }

//         @media (max-width: 550px) {
//             left: -100px;
//         }
//     }

//     -webkit-mask-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
//     mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
//     -webkit-mask-repeat: no-repeat;
//     mask-repeat: no-repeat;
// }

// .home_banner-right_tree {
//     position: absolute;
//     right: 0;
//     bottom: 0;
//     width: 200px;
//     height: 500px;
//     z-index: 4;

//     &_image {
//         width: 100%;
//         height: auto;
//         position: absolute;

//         @media (max-width: 1399px) {
//             right: -30px;
//         }

//         @media (max-width: 1199px) {
//             right: -50px;
//         }

//         @media (max-width: 550px) {
//             right: -100px;
//         }
//     }

//     -webkit-mask-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
//     mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
//     -webkit-mask-repeat: no-repeat;
//     mask-repeat: no-repeat;
// }


// /**********************/
// /** Logged in **/
// .home_banner-logged-in {
//     padding: 50px 0px 0px;
//     text-align: center;

//     @media (max-width: 768px) {
//         padding: 35px 0px 0px;
//     }
// }

// .home_banner-greeting {
//     display: inline-block;
//     position: relative;

//     &:before {
//         content: "";
//         width: calc(125%);
//         height: 100%;
//         position: absolute;
//         z-index: -1;
//         top: 0px;
//         left: -13%;
//         font-style: normal;
//         background-color: $primary;
//         background-position: 0 43%;
//         background-size: cover;
//         -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
//         mask-image: url(https://i.imgur.com/P1bFwVG.png);
//         -webkit-mask-size: 100% 100%;
//         mask-size: 100% 100%;
//         -webkit-mask-repeat: no-repeat;
//         mask-repeat: no-repeat;

//         @media (max-width: 768px) {
//             top: 0;
//             left: -10px;
//             width: calc(100% + 20px);
//         }
//     }
// }