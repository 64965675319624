$promo-image-height: 140px;

// .weekly-bonus-section {
//     padding-top: 24px;
// }

.weekly-bonus-tile-container {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    background-color: $foreground;
    border-radius: $border-radius;
    box-shadow: 0px 1px 3px 1px $background-dark;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.weekly-bonus-tile-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 15px 20px;
    z-index: 2;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-items: baseline;
        align-self: baseline;
        padding: 10px 5px 10px;
    }
}

.weekly-bonus-tile-buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: 767px) {
        margin-top: 15px;
        width: 100%;
        
        &>a {
            width: 100%;
        }
    }
}

.weekly-bonus-image-wrapper {
    position: relative;
    width: auto;
    min-width: 320px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    @media (max-width: 767px) {
        width: 100%;
        flex-grow: 1;
    }
}

.weekly-bonus-image {
    width: auto;
    height: $promo-image-height;
    border-radius: calc($border-radius - 5px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    margin: 0px;
    align-self: flex-start;

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
    }
}

.weekly-bonus-badge {
    display: inline-block;
    font-size: 12px;
    color: $primary-alt;
    font-weight: 600;
}

.weekly-bonus-timer-container {
    position: absolute;
    top: -24px;
    left: 0;
    text-align: left;
    width: 100%;
    background: linear-gradient(150deg, $primary 30%, $primary 100%);
    padding: 0px 15px 40px 15px;
    border-radius: $border-radius $border-radius 0 0;
    z-index: -1;

    @media (max-width: 767px) {
        background: $primary;
        text-align: center;
    }
}

.weekly-bonus-timer-wrapper {
    position: absolute;
    left: $border-radius;
    top: -13px;
    margin: 0;
    font-size: 13px;
    background: $error-full;
    color: $white;
    font-family: 'Roboto Mono', monospace;
    padding: 3px 20px 3px 15px;
    border-radius: $border-radius;
    font-weight: 500;
    z-index: 1;
}