.cashier_toggle {
    display: flex;
    width: 100%;
    background: $background;
    border-radius: $border-radius;
}

.cashier_toggle_button {
    flex-basis: 50% !important;

    outline: none !important;
    box-shadow: none !important;

    color: $font-low !important;
    background: transparent;
    
    font-weight: 500;

    border-radius: $border-radius !important;
    border: 1px solid transparent !important;

    padding: 9px;
    
    &:hover,
    &:active,
    &:focus {
        transform: scale(1);
        background: transparent !important;
        border: 1px solid transparent !important;
        color: $font-full !important;
    }
}

.cashier_toggle .btn-check:checked+.cashier_toggle_button {
    background: $foreground !important;
    color: $font-full !important;
    border: 1px solid $background !important;
    font-weight: 600;
}