.arrow-right,
.arrow-left {
    display: none;
    position: absolute;
    top: calc(50% - 55px);
    right: -35px;
    padding: 13px 24px;
    border-radius: 50%;
    font-size: 1.5rem;
    z-index: 1002;

    background: transparent;
    border: 1px solid transparent;

    color: rgb(0 0 0 / 60%);
    font-weight: 700;
    

    top: -48px;
    right: 0px;
    padding: 4px 11px;
    font-size: 1rem;

    outline: none !important;
    box-shadow: none !important;

    &:focus {
        background: transparent;
        border: 1px solid transparent;
        color: rgb(0 0 0 / 90%);
    }

    &:hover,
    &:active {
        background-color: rgb(0 0 0 / 7%);
        border: 1px solid transparent;
        color: rgb(0 0 0 / 90%);
        
    }
}

.arrow-left {
    left: -35px;
    right: auto;

    right: 50px;
    left: auto;
}

.arrow-right:active,
.arrow-left:active {
    transform: scale(0.95);
}

/* Hiding the arrows on mobile */
@media (min-width: 992px) {
    .arrow-visible {
        display: block;
        background: transparent;
        opacity: 0.2;
    }

    .arrow-active {
        opacity: 1;
    }
}