.game-list-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 1.5rem;

    & > * {
        min-height: 290px;
    }

    // @media (min-width: 1200px) {
        
    // }

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 1rem;

        & > * {
            min-height: 264px;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 1rem;

        & > * {
            min-height: 264px;
        }
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 0.75rem;

        & > * {
            min-height: 244px;
        }
    }

}

// @media (min-width: 1200px) {

//     .game-list-wrapper {
//         grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
//         gap: 1.5rem;
//     }

//     .game-list-wrapper > * {
//         min-height: 290px;
//     }
// }

// @media (min-width: 768px) and (max-width: 1199px) {

//     .game-list-wrapper {
//         grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
//         gap: 1rem;
//     }

//     .game-list-wrapper > * {
//         min-height: 264px;
//     }
// }

// @media (max-width: 767px) {

//     .game-list-wrapper {
//         grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
//         gap: 0.75rem;
//     }

//     .game-list-wrapper > * {
//         min-height: 244px;
//     }
// }