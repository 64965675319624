.promotions-row-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.promotions-row-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.promotions-row-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}