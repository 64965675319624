.deposit-summary {
    padding: 10px 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $success-low;
    border-radius: $border-radius;
}

.deposit-summary-promocode {
    max-width: 430px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 991px) {
        max-width: 270px;
    }
}

.deposit-summary-icon {
    color: $success-full;
    font-size: 24px;
}