.registration_banner_background {
    width: 100%;
    position: absolute;
    z-index: -1;
    margin: -15px;
    
    @media (max-width: 768px) {
        margin: -10px;
    }
}

.registration_banner_image {
    right: -5%;
    width: 45%;
    position: absolute;
    z-index: -1;

    @media (max-width: 768px) {
        right: -5%;
        width: 55%;
    }
}

.registration_banner_arrow {
    left: 15px;
    top: 150px;
    height: 80px;
    position: absolute;
    z-index: 1;

    @media (max-width: 768px) {
        top: 110px;
    }
}

.registration_banner_container {
    margin: 10px 0 50px;
    
    @media (max-width: 768px) {
        margin: 10px 0 40px;
    }
}

.registration_banner_content {
    text-align: center;
    width: 65%;
}

.registration_banner_content_background {
    text-align: center;
    -webkit-mask-image: url(https://i.imgur.com/P1bFwVG.png);
    mask-image: url(https://i.imgur.com/P1bFwVG.png);
    -webkit-mask-size: 105% 100%;
    mask-size: 105% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: 30%;
    mask-position: 30%;
    white-space: nowrap;
    background-color: $font-full;
    padding: 20px 50px;

    @media (max-width: 768px) {
        padding: 15px 20px;
    }
}

.registration_banner_content_badges {
    height: 25px;
    padding: 0 30px;
}

.registration_banner_content_title {
    font-size: 30px;
    color: $primary;
    font-weight: 800;
    margin-bottom: 0;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.registration_banner_content_subtitle {
    font-size: 14px;
    color: #FFF;
    margin-bottom: 0;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}