.registration_banner_arrow_olympus {
    left: 110px;
    top: 168px;
    height: 68px;
    position: absolute;
    z-index: 1;

    @media (max-width: 768px) {
        left: 30px;
        top: 120px;
    }
}