.deposit-payment-method-container {
    padding: 15px 15px 135px;

    @media (max-width: 991px) {
        padding: 10px 10px 135px;
    }
}

.deposit-payment-method-title {
    margin-bottom: 15px;
}

.btn-choose-payment {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    border-radius: $border-radius;
    justify-content: space-between;
    align-items: center;
    background: $background;
    color: $font-mid;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
        background: $background;
        color: $font-full;
    }

    & img {
        max-height: 25px;
        max-width: 300px;
        margin: 5px 0;
        margin-right: 13px;
    }
}

.interac img,
.coinspaid img,
.muchbetter img {
    max-height: 35px;
    margin-top: 0;
    margin-bottom: 0;
}

.choose-payment-bonus {
    margin-left: 10px;
    padding: 0px 10px;
    background: $primary;
    border-radius: 10px;
    font-size: 12px;
}