.game-row-double-wrapper {
    position: relative;
    // width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    grid-auto-columns: 315px;
    min-height: 200px;

    &>* {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    @media (min-width: 1200px) {
        

        &>* {
            margin-right: 1.25rem;
            margin-bottom: 1.25rem;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        //grid-auto-columns: 195px;
        //min-height: 269px;

        &>* {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 767px) {
        //grid-auto-columns: 175px;
        //min-height: 244px;

        &>* {
            margin-right: 0.75rem;
            margin-bottom: 0.75rem;
        }
    }
}


///////////////////

.game-tile-horizontal-wrapper {
    position: relative;
    height: 140px;
    text-decoration: none;
    border-radius: $border-radius;

    display: flex;
    padding: 10px;

    transition: 0.1s ease;
    -moz-transition: 0.21s ease;
    -o-transition: 0.1s ease;
    -ms-transition: 0.1s ease;
    -webkit-transition: 0.1s ease;

    background-color: $foreground;
    box-shadow: 0px 1px 3px 1px $background-dark;

    @media (min-width: 768px) {
        &:hover {
            background-color: $background;
        }
    }

    @media (max-width: 767px) {
        &:active {
            background-color: $background;
        }
    }
}

.game-tile-horizontal-wrapper .tag-container-right {
    top: 15px;
    right: 15px;
}

.game-tile-horizontal-wrapper-placeholder {
    height: 120px;
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: 10px;
}

.game-tile-horizontal-image-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    flex-shrink: 0;
    margin-right: 15px;
}

.game-tile-horizontal-image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    filter: blur(5px);
    border-radius: $border-radius;
    opacity: 0.61;
}

.game-tile-horizontal-image {
    position: relative;
    width: 98px;
    height: auto;
    z-index: 1;
}

.game-tile-horizontal-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.game-tile-horizontal-text-title {
    margin-bottom: 0;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // color: $black;
    color: $font-full;
    font-weight: 600;
    font-size: 0.875rem;

    @media (max-width: 767px) {
        width: 130px;
    }
}

.game-tile-horizontal-text-provider {
    color: $font-low;
    font-weight: 500;
    margin-bottom: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px
}