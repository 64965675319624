.bonus-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px;
    text-align: left;
    padding: 10px;
    border-radius: $border-radius;
    background: $foreground;
    box-shadow: 0px 1px 3px 1px $background-dark;
}

.bonus-tile img {
    width: 100%;
    border-radius: calc($border-radius - 5px);
}

.bonus-tile-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;
    flex-grow: 1;

    @media (max-width: 768px) {
        padding: 10px 5px;
    }
}

.bonus-tile-title {
    // font-weight: 600;
    margin-bottom: 15px;
}

.bonus-tile-content-button {
    width: 100%;
}

.bonus-tile-badge {
    font-size: 12px;
    margin-bottom: 0px;
    color: $font-mid;
}

.bonus-tile-details {
    font-size: 12px;
    margin-bottom: 0px;
    color: $font-mid;
    margin-top: 10px;
}

.btn-bonus-select {
    margin: 0px 10px 10px 10px;
}

.icon-bonus-selected {
    font-size: 18px;
}

.bonus-tile-check {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 7px 3px 9px;
}

.bonus-tile-timer-wrapper {
    position: absolute;
    left: calc($border-radius + 5px);
    top: 0px;
    margin: 0;
    font-size: 13px;
    background: $error-full;
    color: $white;
    font-family: 'Roboto Mono', monospace;
    padding: 3px 20px 3px 15px;
    border-radius: $border-radius;
    
    box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 12%);
}