.bonus-row-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 3px;

    &>* {
        margin-right: 10px;
    }

    @media (min-width: 1200px) {
        grid-auto-columns: 380px;

        &>* {
            margin-right: 1.25rem;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-auto-columns: 320px;

        &>* {
            margin-right: 1rem;
        }
    }

    @media (max-width: 767px) {
        grid-auto-columns: 320px;

        &>* {
            margin-right: 0.75rem;
        }
    }
}

.bonus-row-title-container {
    display: flex;
    align-items: center;
    gap: 7px;

    .faq-page-icon {
        margin-top: 1px;
    }
}