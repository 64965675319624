/***********************************/
/** Preventing scrolling on mobile devices */
.offcanvas-backdrop,
.modal-backdrop,
.modal-content {
    touch-action: none;
    -ms-touch-action: none;
    backdrop-filter: blur(40px);
}

/***********************************/
/** Offcanvas & Modals */
.offcanvas {
    height: 100%;
    max-width: 100%;
    width: 100%;
    border-radius: 0px 0px 0 0; // $border-radius $border-radius 0 0;
    background: $foreground;
    border: none;
    border-top: 0px solid $background;
    overflow: hidden;

    @media (min-width: 992px) {
        height: 100%;
        max-width: 92%;
        width: 550px;
        border-radius: $border-radius 0 0 0;
    }
}

.modal-content {
    //background-color: $foreground;
    border: 0px solid transparent;
    border-radius: $border-radius;
}

.offcanvas-header {
    padding: 13px 20px;
    z-index: 1;
    
    
    @media (min-width: 992px) {
        padding: 13px 30px 0;
        justify-content: start;
        border-radius: $border-radius 0 0 0;
    }
}

.offcanvas_header_title,
.modal-header-title {
    font-size: 18px;
    font-weight: 600;
    color: $font-mid;
    margin-bottom: 0;

    @media (max-width: 1199px) {
        font-size: 16px;
    }
}

.modal-header .btn-close,
.offcanvas_header .btn-close {
    font-size: 12px;
}

.offcanvas-body {
    padding: 0;
}

.modal-footer,
.modal-header {
    border: none;
}


// ////////////
// /// Title and button
// ////////////
// .modal-title {
//     font-size: 1rem;
//     line-height: 150%;
//     letter-spacing: 0.0012em;
//     color: $font-full;
//     margin-bottom: 0;
// }

// .modal-header .btn-close {
//     filter: invert(1) grayscale(100%) brightness(200%);
//     font-size: 12px;
// }

// ////////////
// /// Modal background
// ////////////
// .modal-content {
//     background-color: $foreground;
// }





// ////////////
// /// Rounded corners for all modals
// ////////////
// .modal-content {
//     border-radius: $border-radius;
// }

// ////////////
// /// Alignment classes for modals
// ////////////
// .align-modal-bottom-center .modal-dialog {
//     align-items: flex-start;
// }

// @media (max-width: 767px) {
//     .align-modal-bottom-center .modal-dialog {
//         align-items: flex-end;
//     }
// }