.freespins-row-container {
    background: $foreground;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: $border-radius;
    text-decoration: none !important;
    box-shadow: 0 1px 3px 1px $background-dark;
}

@media (max-width: 767px) {
    .freespins-row-container {
        flex-direction: column;
        // align-items: flex-start;
    }
}

.freespins-row-image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;
}

@media (max-width: 767px) {
    .freespins-row-image-wrapper  {
        width: 100%;
        text-align: center;
        padding-top: 20px;
    }
}

.freespins-row-image {
    position: relative;
    height: 140px;
    width: auto;
    border-radius: $border-radius;
    z-index: 1;
}

@media (max-width: 767px) {
    .freespins-row-image {
        height: 140px;
    }
}

.freespins-row-text-wrapper {
    padding-left: 30px;
}

@media (max-width: 767px) {
    .freespins-row-text-wrapper {
        padding: 15px;
        text-align: center;
    }
}

.freespins-row-text-gold {
    color: $font-full;
    white-space: nowrap;
}

.freespins-row-button-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

@media (max-width: 767px) {
    .freespins-row-button-wrapper {
        flex-grow: 1;
        display: flex;
        width: 100%;
        padding-right: 0px;
        padding-bottom: 20px;
        justify-content: center;
    }
}

.freespins-row-icon {
    font-size: 20px;
    color: $font-full;
}