.deposit-redirect-container {
    padding: 15px 15px 155px;

    @media (max-width: 991px) {
        padding: 10px 10px 155px;
    }
}

.deposit-redirect-container .row {
    --bs-gutter-x: 0 !important;
}

.deposit-redirect-title {
    margin-bottom: 15px;
}

.deposit-redirect-container {
    position: relative;
}

.deposit-redirect-summary-container {
    position: relative;
    text-align: center;
}

.deposit-redirect-summary {
    padding: 15px;
    border-radius: $border-radius;
    background: $success-low;
}

.deposit-check-icon {
    color: $success-full;
    font-size: 30px;
}

.btn-deposit-redirect {
    margin-top: 10px;
    border-radius: $border-radius;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
}

.deposit_redirect_section_images {
    margin-bottom: 10px;
    gap: 10px;
    // display: flex;
    // justify-content: center;

    &>* {
        height: 35px;
    }
}

.deposit-frame {
    width: 100%;
    height: 100%;
}

.deposit-frame-modal .btn-close {
    font-size: 20px;
    opacity: 1;
}